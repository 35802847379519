import CheckboxGroupComponent from '@wix/thunderbolt-elements/src/components/CheckboxGroup/viewer/CheckboxGroup';
import CheckboxGroupController from '@wix/thunderbolt-elements/src/components/CheckboxGroup/viewer/CheckboxGroup.controller';


const CheckboxGroup = {
  component: CheckboxGroupComponent,
  controller: CheckboxGroupController
};


export const components = {
  ['CheckboxGroup']: CheckboxGroup
};

